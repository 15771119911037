<template>
	<header>
		<h1 class="title mr-3">
			{{ boxes.title }}
		</h1>
		<div>
			<input type="checkbox" id="outdated" name="outdated" @change="toggleOutdated">
			<label for="outdated">Показывать списанные</label>
		</div>
	</header>
	<ul class="list">
		<li class="list__element pointer" v-for="item in filteredList" :key="item.id">
			<router-link :to="`/boxes/${item.id}`">
				<p>
					<strong>rfid:</strong> {{ item.rfid }}<br>
					<strong>Зарегистрирован:</strong> {{ formatRegisterDate(item.registerDate) }}<br>
					<span v-if="item.endDate">
						<strong>Списан:</strong> {{ formatRegisterDate(item.endDate) }}<br>
					</span>
					<strong>Вес:</strong> {{ item.weight }}
				</p>
			</router-link>
		</li>
	</ul>
	<BasePagination
		v-model:value="pagination.page"
		:max="pagination.totalPages"
		@update:value="getData"
	/>
	<div class="add">
		<IconPlus @click="$router.push(`/boxes/new`)" color="white" :diameter="32" circle />
	</div>

</template>

<script>
import BOOKS from '@/modules/constants/books'

import { onMounted, reactive, ref, computed } from 'vue'
import { useApi } from '@/modules/api'

import BasePagination from '@/components/ui/BasePagination'
import IconPlus from '@/components/ui/icons/IconPlus'

export default {
	name: 'Boxes',
	components: { BasePagination, IconPlus },
	setup () {
		const { boxes } = BOOKS
		const list = ref([])
		const isShowOutdated = ref(false)

		const pagination = reactive({
			show: true,
			page: 0,
			size: 20,
			totalPages: 0
		})

		const getData = async () => {
			const { get, data } = useApi(boxes.endpoint)

			const urlParametrs = `?offset=0&page=${pagination.page}&size=${pagination.size}&sort=desc`

			await get(urlParametrs)

			const { content, totalPages } = data.value
			pagination.totalPages = totalPages

			list.value = content
		}

		const toggleOutdated = () => {
			isShowOutdated.value = !isShowOutdated.value
		}

		const formatRegisterDate = (date) => {
			return new Date(date).toLocaleDateString('ru-RU', { day: 'numeric', month: 'numeric', year: 'numeric' })
		}

		const filteredList = computed(() => list.value.filter(item => {
			return isShowOutdated.value ? true : !item.endDate || new Date() < new Date(item.endDate)
		}))

		onMounted(async () => {
			await getData()
		})

		return {
			boxes,
			list,
			filteredList,
			pagination,
			getData,
			formatRegisterDate,
			toggleOutdated
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	label {
		margin-left: 4px;
	}
}
.list {
	margin-top: 20px;
	&__element {
		transition: background-color 0.3s ease;
		padding: 4px;
		display: grid;

		p {
			b {
				display: block;
				margin-bottom: 2px;
			}
		}

		@include until-mobile {
			p + p {
				margin-top: 4px;
			}
		}

		@include for-tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				&:last-child {
					// text-align: right;
				}
			}

			&.hover {
				&:hover {
					background-color: rgba($bgMain, 0.1);
				}
			}
		}

		& + & {
			border-top: 1px solid $borderPrimary;
		}
	}
}
.add {
	position: fixed;
	top: 60px;
	right: 20px;
}
</style>
